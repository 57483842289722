<template>
  <div class="bg-wrap">
    <section class="mine-wrap">
      <div class="nav">
        <div class="nav-wrap">
          <div class="head">
            <ApartmentOutlined />
            <span>{{ $t("mine.unit_management") }}</span>
            <!-- 单位管理 -->
          </div>
          <ul class="menu">
            <li
              :class="{ active: currentView === item.key }"
              v-for="item in menus"
              :key="item.key"
              @click="clickJump(item)"
            >
              <component :is="item.icon" />
              <span>{{ item.name }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="content">
        <component :is="currentView"></component>
      </div>
    </section>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
const files = require.context("./components", false, /\.vue$/);
const components = {};
files.keys().forEach((key) => {
  components[key.replace(/(\.\/|\.vue)/g, "")] = files(key).default;
});
import { ref, watch } from "vue";
import ls from "@/utils/local-storage";
import { useRoute, useRouter } from "vue-router";
export default {
  components,
  setup() {
    const { t: $t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const menus = ref([
      {
        icon: "TeamOutlined",
        name: $t("enterprise.personnel_infor", 1),
        // 人员信息
        key: "memberList",
      },
      {
        icon: "SolutionOutlined",
        name: $t("enterprise.personnel_learning", 1),
        // 人员学习情况
        key: "memberLearn",
      },
    ]);
    const currentView = ref(menus.value[0].key);

    const setCurrentView = () => {
      let routeCurrent = route.params.currentView;
      if (routeCurrent) ls.set("enterprise_currentView", routeCurrent);
      menus.value.map((item) => {
        if (ls.get("enterprise_currentView") == item.key)
          currentView.value = item.key;
      });
    };

    setCurrentView();

    const clickJump = (item) => {
      router.replace({
        name: "enterprise",
        params: { currentView: item.key },
      });
    };

    watch(
      () => route.params.currentView,
      () => setCurrentView()
    );

    return {
      menus,
      currentView,
      clickJump,
    };
  },
};
</script>

<style lang="less" scoped>
.bg-wrap {
  background-color: @color-page-gray;
}
.mine-wrap {
  .mixinWrap();
  .mixinFlex(space-between);
  padding: 32px 0 40px;
}

.nav {
  width: 258px;
  .nav-wrap {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
    .head {
      height: 72px;
      border-bottom: 1px solid #e2e2e2;
      line-height: 72px;
      font-size: 24px;
      padding-left: 40px;
      span {
        padding-right: 10px;
      }
    }
    .menu {
      li {
        height: 64px;
        padding-left: 40px;
        line-height: 64px;
        font-size: 16px;
        position: relative;
        cursor: pointer;
        user-select: none;
        span {
          padding-right: 10px;
        }
        &::before {
          content: "";
          width: 3px;
          height: 32px;
          background-color: #fff;
          position: absolute;
          top: 16px;
          left: 0;
          transition: all 0.2s;
        }
        &:hover,
        &.active {
          &::before {
            background-color: @color-theme;
          }
        }
      }
    }
  }
}

.content {
  width: 918px;
}
</style>
